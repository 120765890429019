<div class="sky-card">
  <div class="sky-card-header">
    <h1 class="h2 mb-0">Settings</h1>
  </div>
</div>

<!-- Storage -->
<div class="sky-card mt">
  <div class="sky-card-header">
    <h2 class="h3 mb-0">
      <mat-icon>swap_horiz</mat-icon>
      Storage
    </h2>
  </div>
  <div class="sky-card-body">
    <div class="container ws-pl">
      <p>
        Your data is currently being stored to:
        <b class="c-new">{{ storageProviderName }}</b>.
      </p>
      To change the place your data is stored, please visit the Storage page.<br/>
      If you want to transfer your data you should first export it here. After that, switch storage and then import the data back on this page.
      <div class="mt sky-flex flex-wrap">
        <a class="button" [routerLink]="'/storage'">
          <mat-icon class="menu-icon">database</mat-icon>
          <span class="menu-label">Go to Storage</span>
        </a>
        <button type="button" class="item" (click)="import()">
          <mat-icon class="menu-icon">download</mat-icon>
          <span class="menu-label">Import data</span>
        </button>
        <button type="button" class="item" (click)="export()">
          <mat-icon class="menu-icon">upload</mat-icon>
          <span class="menu-label">Export data</span>
        </button>
        <button type="button" class="item clear-data" (click)="clear()">
          <mat-icon class="menu-icon">delete</mat-icon>
          <span class="menu-label">Clear data</span>
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Spirit trees -->
<div class="sky-card mt">
  <div class="sky-card-header">
    <h1 class="h2 mb-0">
      <mat-icon style="transform:scaleY(-1)">network_node</mat-icon>
      Spirit trees
    </h1>
  </div>
  <div class="sky-card-body">
    <div class="container">
      <p>
        @if (unlockConnectedNodes) {
          All the items leading up to the item you unlock in a spirit tree will also be unlocked.<br/>
          Likewise, when you lock an item the items beyond it will be locked. You can disable this here.
        } @else {
          When you unlock an item in a spirit tree only that item will be unlocked.<br/>
          You can choose to also unlock all preceding items below.
        }
      </p>
      <div class="sky-flex flex-wrap mt">
        <button (click)="toggleConnectedNodes()">
          <mat-icon class="menu-icon">{{unlockConnectedNodes ? 'check_box' : 'check_box_outline_blank'}}</mat-icon>
          <span class="menu-label">Unlock connected items</span>
        </button>
    </div>
  </div>
</div>

<!-- Date -->
<div class="sky-card mt">
  <div class="sky-card-header">
    <h2 class="h3 mb-0">
      <mat-icon>date_range</mat-icon>
      Date
    </h2>
  </div>
  <div class="sky-card-body">
    <div class="container ws-pl">
      Change the date displayed in the application. Today's date is {{ today | datetime: dateFormat }}.
      <div class="sky-flex flex-wrap mt">
        <button *ngFor="let format of dateFormats" [class.highlight]="dateFormat === format" type="button" class="item ws-nw" (click)="setDateFormat(format)">
          {{ format | lowercase }}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Theme -->
<div class="sky-card mt">
  <div class="sky-card-header">
    <h2 class="h3 mb-0">
      <mat-icon>color_lens</mat-icon>
      Theme
    </h2>
  </div>
  <div class="sky-card-body">
    <div class="container ws-pl">
      Change the theme of the application.
      <div class="sky-flex flex-wrap mt">
        <button *ngFor="let theme of themes" [class.highlight]="currentTheme === theme.value" type="button" class="item ws-nw" (click)="setTheme(theme)">
          {{ theme.name }}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Wiki -->
<div class="sky-card mt">
  <div class="sky-card-header">
    <h2 class="h3">Wiki</h2>
  </div>
  <div class="sky-card-body">
    <div class="sky-flex flex-wrap wiki-toggle-tab">
      <button (click)="toggleWikiTab()">
        <mat-icon class="menu-icon">{{wikiNewTab ? 'check_box' : 'check_box_outline_blank'}}</mat-icon>
        <span class="menu-label">Navigate to wiki in new tab</span>
      </button>
    </div>
  </div>
</div>
