<div class="keyboard-grid">
  <!-- Global -->
  <div class="keyboard-section">Global</div>
  <div class="keyboard-key mono">Ctrl + Shift + F</div>
  <div class="keyboard-description">
    Go to dashboard search bar.
  </div>
  <div class="keyboard-key mono">Ctrl + Shift + S</div>
  <div class="keyboard-description">
    Force save data.
  </div>
  <div class="keyboard-key mono">Ctrl + Shift + 1-9</div>
  <div class="keyboard-description">
    Change theme
  </div>

  <div class="keyboard-key mono">C</div>
  <div class="keyboard-description">
    Go to Currency.
  </div>
  <div class="keyboard-key mono">D</div>
  <div class="keyboard-description">
    Go to Dashboard.
  </div>
  <div class="keyboard-key mono">E</div>
  <div class="keyboard-description">
    Go to Events.
  </div>
  <div class="keyboard-key mono">I</div>
  <div class="keyboard-description">
    Go to Items.
  </div>
  <div class="keyboard-key mono">R</div>
  <div class="keyboard-description">
    Go to Realms.
  </div>
  <div class="keyboard-key mono">P</div>
  <div class="keyboard-description">
    Go to Spirits.
  </div>
  <div class="keyboard-key mono">S</div>
  <div class="keyboard-description">
    Go to Seasons.
  </div>
  <div class="keyboard-key mono">W</div>
  <div class="keyboard-description">
    Go to Winged Light.
  </div>

  <!-- Spirit Trees -->
  <div class="keyboard-section">Spirit Trees</div>
  <div class="keyboard-key mono">F</div>
  <div class="keyboard-description">
    Enable Favourite mode.
  </div>
  <div class="keyboard-key mono">N</div>
  <div class="keyboard-description">
    Enable Navigate (Find) mode.
  </div>
  <div class="keyboard-key mono">U</div>
  <div class="keyboard-description">
    Enable Unlock mode.
  </div>
</div>
