<div class="sky-card">
  <div class="sky-card-header">
    <h1 class="h2 mb-0">Nesting Workshop</h1>
  </div>
  <div class="sky-card-body">
    <app-wiki-link [wiki]="{href: 'https://sky-children-of-the-light.fandom.com/wiki/Nesting_Workshop'}"></app-wiki-link>
  </div>
</div>

<div class="mt">
  <app-card [foldable]="true" [folded]="false" [title]="'Nesting Challenges'">
    <div class="sky-flex scroll-x">
      @for (spirit of challengeSpirits; track spirit.guid) {
        <app-spirit-tree [tree]="spirit.tree!" [name]="spirit.name"></app-spirit-tree>
      }
    </div>
  </app-card>
</div>

  <div class="mt">
    <app-card [foldable]="true" [folded]="false" [title]="'Workshop Props'">
      <div class="sky-flex flex-wrap">
        <div class="shop-rotation d-inline-block">
          <b class="d-block mb-half">
            Introduction
          </b>
          <app-item-list [itemList]="freeItemList" [highlightNode]="highlightNode"></app-item-list>
        </div>
        @for (itemList of itemLists; let i = $index; track i) {
          <div class="shop-rotation d-inline-block">
            <b class="d-block mb-half" [class.highlight-attention]="i === iRotation">
              Rotation {{ i + 1 }}
            </b>
            <app-item-list class="d-inline-block" [itemList]="itemList" [highlightNode]="highlightNode"></app-item-list>
          </div>
        }
        <div class="shop-rotation d-inline-block">
          <b class="d-block mb-half">
            Corrections
          </b>
          <app-item-list [itemList]="correctionItemList" [highlightNode]="highlightNode"></app-item-list>
        </div>
      </div>
      <div class="container mt">
        <app-cost [cost]="cost" [remaining]="remainingCost"></app-cost>
      </div>
    </app-card>
  </div>
