<div class="sky-card">
  <div class="sky-card-header">
    <h1 class="h2 mb-0">Outfit vault</h1>
  </div>
  <div class="sky-card-body">
    <div class="container">
      <mat-icon class="v-top">construction</mat-icon>
      With the recent changes to the Sky in-game closets, the Outfit vault needs some maintenance. Please check back later!
    </div>
  </div>
</div>
